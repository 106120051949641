<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row justify="center">
            <v-col
                cols="12"
            >
                <div class="d-flex justify-center">
                    <div
                        class="d-flex flex-column "
                    >
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="#0060e6"
                            indeterminate
                        />
                        <div>{{ $t('customerportal-loggingIn') }}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() { 
        let urlParams = new URLSearchParams(window.location.search)
        if(urlParams.has('error')){
            this.$router.push({ name: 'Login' })
        } else {
            this.$criipto.redirect.match()
                .then(response => {
                    this.LogInCriipto({
                        jwtToken: response.id_token
                    })
                        .then(loggedIn => {
                            if(loggedIn){
                                this.$router.push({ name: 'PNumberSelector' })
                            }
                            else {
                                this.$router.push({ name: 'Login' })
                            }                  
                        })
                        .finally(() => {
                            this.working = false
                        })
                })
        }
    },    
    methods: {
        ...mapActions({
            LogInCriipto: 'LogInCriipto'
        })
    }
}
</script>